<template>
  <v-container fluid>
    <v-tabs-items
      touchless
      class="transparent"
      v-model="selectedAppBarTab"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <FilterHeader
          @newValues="updateValues($event)"
          :use-date="true"
          :use-channels="true"
          :use-locations="true"
        />
        <div :key="transientRefreshKey">
          <TransientDash
            v-if="tab.key !== 'all'"
            :key="tab.key"
            :title="titleWithDate(tab)"
            :description="tab.description"
            :namespace="tab.key"
            :config="{}"
            :fixed-header="tab.transientDashOptions.useFixedHeader"
            :channels="channels"
            :locations="locations"
            :start-time-in-millis="startTimeInMillis"
            :end-time-in-millis="endTimeInMillis"
            style="margin-bottom: 20px;"
          />
          <template
            v-for="(dash, index) in tabs"
          >
            <TransientDash
              v-if="tab.key === 'all' && dash.key !== 'all'"
              :key="index"
              :title="titleWithDate(dash)"
              :namespace="dash.key"
              :config="{}"
              :fixed-header="dash.transientDashOptions.useFixedHeader"
              :channels="channels"
              :locations="locations"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              style="margin-bottom: 20px;"
            />
          </template>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import FilterHeader from "@/components/universalFilter/FilterHeader.vue"
import TransientDash from "@/components/dash/TransientDash"

export default {
	name: "EndOfDayReport",
	components: {
		TransientDash,
		FilterHeader
	},
	data() {
		return {
			oneDayInMillis: 86400000,
			transientRefreshKey: 0,
			startTimeInMillis: (new Date).getTime(),
			endTimeInMillis: (new Date).getTime(),
			locations: [],
			channels: [],
		}
	},
	created() {
		this.$store.commit("updateAppBarTabs", this.tabs)
		if (Array.isArray(this.tabs) && this.tabs.length > 0) {
			if (this.tabs[0].key) {
				this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
			}
		}
		this.startTimeInMillis = new Date().getTime() - this.oneDayInMillis * 7
		this.endTimeInMillis = new Date().getTime()
	},
	computed: {
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		tabs() {
			return [
				{
					title: this.$t('endOfDayReport'),
					key: "all",
					description: this.$t('endOfDayReportDescription')
				},
				{
					title: this.$t('paymentsByDay'),
					key: "payments_summary",
					description: this.$t('paymentsByPeriodDescription'),
					transientDashOptions: {
						useFixedHeader: false
					}
				},
				{
					title: this.$t('categorySummaryList'),
					key: "category_summary_list",
					description: this.$t('categorySummaryListDescription'),
					transientDashOptions: {
						useFixedHeader: false
					}
				},
				{
					title: this.$t('invoices'),
					key: "invoices_list",
					description: this.$t('invoiceListDescription'),
					transientDashOptions: {
						useFixedHeader: true
					}
				},
				{
					title: "Item Summary List",
					key: "item_summary_list",
					description: this.$t('itemSummaryListDescription'),
					transientDashOptions: {
						useFixedHeader: true
					}
				}
			]
		}
	},
	mounted() {
		this.$store.commit("updateAppBarTabs", this.tabs)
		this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
	},
	methods: {
		changeTabs() {
			this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
		},
		titleWithDate(tab) {
			const startString = new Date(this.startTimeInMillis)
				.toString()
				.slice(0, 24)
			const endString = new Date(this.endTimeInMillis - 1)
				.toString()
				.slice(0, 24)
			return tab.title + ": " + startString + " - " + endString
		},
		updateValues(payload) {
			this.startTimeInMillis = payload.startTimeInMillis
			this.endTimeInMillis = payload.endTimeInMillis
			this.locations = payload.locations
			this.channels = payload.channels
			this.transientRefreshKey += 1
		}
	}
}
</script>
